exports.components = {
  "component---src-view-404-tsx": () => import("./../../../src/view/404.tsx" /* webpackChunkName: "component---src-view-404-tsx" */),
  "component---src-view-about-index-tsx": () => import("./../../../src/view/about/index.tsx" /* webpackChunkName: "component---src-view-about-index-tsx" */),
  "component---src-view-agreements-privacy-policy-tsx": () => import("./../../../src/view/agreements/privacyPolicy.tsx" /* webpackChunkName: "component---src-view-agreements-privacy-policy-tsx" */),
  "component---src-view-agreements-terms-of-sale-tsx": () => import("./../../../src/view/agreements/termsOfSale.tsx" /* webpackChunkName: "component---src-view-agreements-terms-of-sale-tsx" */),
  "component---src-view-agreements-terms-of-service-tsx": () => import("./../../../src/view/agreements/termsOfService.tsx" /* webpackChunkName: "component---src-view-agreements-terms-of-service-tsx" */),
  "component---src-view-contact-index-tsx": () => import("./../../../src/view/contact/index.tsx" /* webpackChunkName: "component---src-view-contact-index-tsx" */),
  "component---src-view-download-business-index-tsx": () => import("./../../../src/view/download/business/index.tsx" /* webpackChunkName: "component---src-view-download-business-index-tsx" */),
  "component---src-view-download-index-tsx": () => import("./../../../src/view/download/index.tsx" /* webpackChunkName: "component---src-view-download-index-tsx" */),
  "component---src-view-features-index-tsx": () => import("./../../../src/view/features/index.tsx" /* webpackChunkName: "component---src-view-features-index-tsx" */),
  "component---src-view-features-multi-monitor-index-tsx": () => import("./../../../src/view/features/multiMonitor/index.tsx" /* webpackChunkName: "component---src-view-features-multi-monitor-index-tsx" */),
  "component---src-view-features-privacy-mode-index-tsx": () => import("./../../../src/view/features/privacyMode/index.tsx" /* webpackChunkName: "component---src-view-features-privacy-mode-index-tsx" */),
  "component---src-view-features-remote-index-tsx": () => import("./../../../src/view/features/remote/index.tsx" /* webpackChunkName: "component---src-view-features-remote-index-tsx" */),
  "component---src-view-features-transfer-index-tsx": () => import("./../../../src/view/features/transfer/index.tsx" /* webpackChunkName: "component---src-view-features-transfer-index-tsx" */),
  "component---src-view-features-unattended-access-index-tsx": () => import("./../../../src/view/features/unattendedAccess/index.tsx" /* webpackChunkName: "component---src-view-features-unattended-access-index-tsx" */),
  "component---src-view-guidance-index-tsx": () => import("./../../../src/view/guidance/index.tsx" /* webpackChunkName: "component---src-view-guidance-index-tsx" */),
  "component---src-view-home-index-tsx": () => import("./../../../src/view/home/index.tsx" /* webpackChunkName: "component---src-view-home-index-tsx" */),
  "component---src-view-pricing-business-index-tsx": () => import("./../../../src/view/pricing/business/index.tsx" /* webpackChunkName: "component---src-view-pricing-business-index-tsx" */),
  "component---src-view-pricing-index-tsx": () => import("./../../../src/view/pricing/index.tsx" /* webpackChunkName: "component---src-view-pricing-index-tsx" */),
  "component---src-view-products-business-index-tsx": () => import("./../../../src/view/products/business/index.tsx" /* webpackChunkName: "component---src-view-products-business-index-tsx" */),
  "component---src-view-promo-campagin-index-tsx": () => import("./../../../src/view/promo-campagin/index.tsx" /* webpackChunkName: "component---src-view-promo-campagin-index-tsx" */)
}

