export function setCookie(name: string, value: string | number, Days?: number) {
  const domain = '.avica.link'
  if (Days) {
    const OneDay = 24 * 60 * 60
    const maxAge = OneDay * Days
    document.cookie = `${name}=${value};max-age=${maxAge};expires=${new Date(
      Date.now() + maxAge * 1000
    )};path=/;domain=${domain}`
  } else {
    // 不传入过期时间时，设置的 cookie 有效期为 Session，即只在当前会话有效，浏览器一旦关闭，该 Cookie 就会被删除
    document.cookie = `${name}=${value};path=/;domain=${domain}`
  }
}

export function getCookie(name: string) {
  const parts = document.cookie.split(/;\s*/)
  for (let i = 0; i < parts.length; i += 1) {
    const vIndex = parts[i].indexOf('=')
    const key = parts[i].substring(0, vIndex)
    const value = parts[i].substring(vIndex + 1)
    if (key === name) {
      return value
    }
  }
  return ''
}
