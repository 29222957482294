import './global.css'
import { Storage } from '@raylink-overseas/common/constants/storage'
import { setCookie, getCookie } from '@raylink-overseas/common/utils/cookie'
import getFingerprint from '@raylink-overseas/common/utils/fingerprint'

export const onClientEntry = async () => {
  // 保存首次记录到的来源链接，24小时不覆盖(营销需求)
  const comefromTime = getCookie(Storage.comefromTime)
  if (!comefromTime || Date.now() > +comefromTime) {
    let referrer = document.referrer || document.URL

    // 不含utm_source的来源地址（首页的上一个地址，比如百度、谷歌），需要带上当前官网链接的utm_source参数
    if (document.referrer && !document.referrer.includes('utm_source')) {
      // 后端接口限制来源字段长度为2000字符，前端也需限制。将来源地址截取1600字符，给utm参数保留400字符的长度
      referrer = referrer.slice(0, 1600)
      if (referrer.includes('&')) {
        referrer = `${referrer}&${window.location.search.slice(1)}`
      } else {
        referrer += window.location.search
      }
    }

    // 来源24小时不覆盖
    setCookie(
      Storage.comefromTime,
      new Date().setDate(new Date().getDate() + 1),
      1
    )
    // 来源保持7天
    setCookie(Storage.comefrom, referrer.slice(0, 2000), 7)
  }

  // 存储浏览器指纹
  const finger = await getFingerprint()
  localStorage.setItem(Storage.fingerprint, finger as string)
}
