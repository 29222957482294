export const Storage = {
  userKey: 'userKey',
  platform: 'platform',
  comefrom: 'comefrom',
  agreeCookie: 'agreeCookie', // 意见征求横幅，是否同意cookies被第三方收集
  supportFolders: 'supportFolders',
  fingerprint: 'fingerprint', // 浏览器指纹
  comefromTime: 'comefromTime', // 记录来源链接过期时间
  gtagDownloadTime: 'gtagDownloadTime', // 第一次回传下载事件的时间,（谷歌广告埋点需求，单个浏览器7天内只回传一次）
}
